import { submitJob, pollJobStatus, formatResponse } from "@src/services/fashionhub.service";
import React, { useState, useRef, useEffect, useCallback } from "react";
import * as fabric from "fabric";
import {
    FaBrush,
    FaUndo,
    FaRedo,
    FaTrash,
    FaSearch,
    FaSearchMinus,
    FaSearchPlus,
} from "react-icons/fa"; // v6
import { useDropzone } from "react-dropzone";
import { Upload, ArrowLeft, Wand2, X } from "lucide-react";

const InpaintingTool = ({ model, onBack }) => {
    const [canvas, setCanvas] = useState(null);
    const [brushSize, setBrushSize] = useState(20);
    const [zoom, setZoom] = useState(1);
    const [imageFile, setImageFile] = useState(null);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [files, setFiles] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [progress, setProgress] = useState(0);
    const canvasRef = useRef(null);
    const imageRef = useRef(null);
    const historyRef = useRef([]);
    const historyIndexRef = useRef(-1);

    useEffect(() => {
        let newCanvas;
        if (canvasRef.current) {
            newCanvas = new fabric.Canvas(canvasRef.current, {
                width: 400,
                height: 300,
            });
            newCanvas.isDrawingMode = true;
            newCanvas.freeDrawingBrush = new fabric.PencilBrush(newCanvas);
            setCanvas(newCanvas);
        }
        if (newCanvas) {
            newCanvas.isDrawingMode = true;
            newCanvas.freeDrawingBrush.width = brushSize;
            newCanvas.freeDrawingBrush.color = "#00aeff";

            newCanvas.on("path:created", function (e) {
                e.path.set();
                newCanvas.renderAll();
            });
        }
        return () => {
            if (newCanvas) {
                newCanvas.dispose();
            }
        };
    }, [brushSize]);

    const handleBrushSizeChange = (e) => {
        setBrushSize(parseInt(e.target.value));
    };

    const handleUndo = useCallback(() => {
        if (historyIndexRef.current > 0) {
            historyIndexRef.current--;
            const prevState = historyRef.current[historyIndexRef.current];
            canvas.loadFromJSON(prevState, () => {
                canvas.renderAll();
            });
        }
    }, [canvas]);

    const handleRedo = useCallback(() => {
        if (historyIndexRef.current < historyRef.current.length - 1) {
            historyIndexRef.current++;
            const nextState = historyRef.current[historyIndexRef.current];
            canvas.loadFromJSON(nextState, () => {
                canvas.renderAll();
            });
        }
    }, [canvas]);

    const handleReset = () => {
        canvas.clear();
        if (imageRef.current) {
            canvas.setBackgroundImage(imageRef.current, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / imageRef.current.width,
                scaleY: canvas.height / imageRef.current.height,
            });
        }
        resetHistory();
    };

    const handleZoomIn = () => {
        setZoom((prevZoom) => Math.min(prevZoom * 1.2, 5));
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => Math.max(prevZoom / 1.2, 0.1));
    };

    const resetZoom = () => {
        setZoom(1);
    };

    useEffect(() => {
        if (canvas) {
            canvas.setZoom(zoom);
            canvas.setWidth(400 * zoom);
            canvas.setHeight(300 * zoom);
            canvas.renderAll();
        }
    }, [canvas, zoom]);

    const resetHistory = () => {
        historyRef.current = [JSON.stringify(canvas)];
        historyIndexRef.current = 0;
    };

    useEffect(() => {
        if (canvas) {
            canvas.on("object:added", () => {
                if (historyIndexRef.current < historyRef.current.length - 1) {
                    historyRef.current = historyRef.current.slice(0, historyIndexRef.current + 1);
                }
                historyRef.current.push(JSON.stringify(canvas));
                historyIndexRef.current = historyRef.current.length - 1;
            });
        }
    }, [canvas]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const formData = new FormData();

            // Handle image_bytes
            if (imageFile) {
                formData.append("image_bytes", imageFile);
                console.log("imageFile", imageFile);
            } else {
                throw new Error("No image file selected");
            }

            // Generate mask_bytes
            if (canvas) {
                const maskDataUrl = canvas.toDataURL({
                    format: "png",
                    multiplier: imageRef.current.width / canvas.width,
                });
                const maskBlob = await (await fetch(maskDataUrl)).blob();
                const maskFile = new File([maskBlob], "mask.png", {
                    type: "image/png",
                    lastModified: new Date().getTime(),
                });

                formData.append("mask_bytes", maskFile);
            } else {
                throw new Error("Canvas not initialized");
            }

            // Append other form fields
            model.fields.forEach((field) => {
                if (field.type !== "file" && field.type !== "canvas") {
                    const value = event.target[field.name].value;
                    formData.append(field.name, value);
                    console.log(`${field.name}:`, value);
                }
            });
            const { request_id } = await submitJob(model.endpoint, formData);
            pollJobStatus(
                model.endpoint,
                request_id,
                (result) => {
                    setResult(formatResponse(result.response));
                    setLoading(false);
                },
                (error) => {
                    setError(error);
                    setLoading(false);
                },
            );
        } catch (error) {
            console.error("Submission error:", error);
            setError(error.message || "An unexpected error occurred");
            setLoading(false);
        }
    };

    const handleImageUpload = useCallback(
        (file) => {
            setImageFile(file);
            const reader = new FileReader();

            reader.onload = (e) => {
                const imageUrl = e.target.result;
                const imgElement = document.createElement("img");
                imgElement.src = imageUrl;
                imgElement.onload = () => {
                    const imageInstance = new fabric.Image(imgElement, {
                        angle: 0,
                        opacity: 1,
                        cornerSize: 30,
                    });

                    const loadImageOnCanvas = () => {
                        if (canvas) {
                            canvas.clear();
                            const cw = canvas.width;
                            const ch = canvas.height;
                            if (cw > ch) {
                                imageInstance.scaleToWidth(cw);
                                imageInstance.scaleToHeight(ch);
                            } else {
                                imageInstance.scaleToHeight(ch);
                                imageInstance.scaleToWidth(cw);
                            }
                            canvas.add(imageInstance);
                            canvas.centerObject(imageInstance);
                            canvas.renderAll();

                            imageRef.current = imageInstance;
                            resetHistory();
                        } else {
                            console.log("Canvas not ready, retrying...");
                            setTimeout(loadImageOnCanvas, 100);
                        }
                    };

                    loadImageOnCanvas();
                };
            };

            reader.onerror = (error) => {
                console.error("Error reading file:", error);
            };

            reader.readAsDataURL(file);
        },
        [canvas, resetHistory],
    );

    const onDrop = useCallback(
        (acceptedFiles, fieldName) => {
            setFiles((prev) => ({
                ...prev,
                [fieldName]: acceptedFiles[0],
            }));
            setValidationErrors((prev) => ({
                ...prev,
                [fieldName]: false,
            }));

            if (acceptedFiles[0] && acceptedFiles[0].type.startsWith("image/")) {
                handleImageUpload(acceptedFiles[0]);
            }
        },
        [handleImageUpload],
    );

    const renderField = (field) => {
        switch (field.type) {
            case "file":
                const { getRootProps, getInputProps, isDragActive } = useDropzone({
                    onDrop: (acceptedFiles) => onDrop(acceptedFiles, field.name),
                    accept: "image/*",
                    multiple: false,
                });

                return (
                    <div className="col-span-2">
                        <div
                            {...getRootProps()}
                            className={`mt-1 flex justify-center items-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md h-[218px] ${
                                isDragActive
                                    ? "border-primary bg-primary bg-opacity-10"
                                    : validationErrors[field.name]
                                    ? "border-red-500"
                                    : "border-grey-300"
                            } hover:border-primary transition-colors`}
                        >
                            <div className="space-y-1 text-center">
                                {files[field.name] ? (
                                    <img
                                        src={URL.createObjectURL(files[field.name])}
                                        alt="Preview"
                                        className="mx-auto h-32 w-auto object-cover rounded-md"
                                    />
                                ) : (
                                    <Upload className="mx-auto h-12 w-12 text-grey-400" />
                                )}
                                <div className="flex text-sm text-grey-600">
                                    <label
                                        htmlFor={field.name}
                                        className="relative cursor-pointer rounded-md font-medium text-primary hover:text-primary-hover focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary"
                                    >
                                        <span>
                                            {files[field.name] ? "Change file" : "Upload a file"}
                                        </span>
                                        <input
                                            {...getInputProps()}
                                            id={field.name}
                                            name={field.name}
                                            className="sr-only"
                                        />
                                    </label>
                                    {!files[field.name] && <p className="pl-1">or drag and drop</p>}
                                </div>
                                <p className="text-xs text-grey-500">PNG, JPG, GIF up to 10MB</p>
                            </div>
                        </div>
                        {validationErrors[field.name] && (
                            <p className="text-red-500 text-xs mt-1">
                                {validationErrors[field.name]}
                            </p>
                        )}
                    </div>
                );

            case "textarea":
                return (
                    <div className="col-span-2">
                        <textarea
                            name={field.name}
                            rows={4}
                            className={`mt-1 block w-full rounded-md border ${
                                validationErrors[field.name] ? "border-red-500" : "border-grey-300"
                            } shadow-sm py-2 px-3 bg-white text-text-primary focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm`}
                            required={field.required}
                            defaultValue={field.default}
                            placeholder={field.placeholder || "Enter additional details"}
                        />
                        {validationErrors[field.name] && (
                            <p className="text-red-500 text-xs mt-1">
                                {validationErrors[field.name]}
                            </p>
                        )}
                    </div>
                );

            case "select":
                return (
                    <div className="col-span-2 md:col-span-1">
                        <div className="relative">
                            <select
                                name={field.name}
                                className={`block w-full mt-1 rounded-md border ${
                                    validationErrors[field.name]
                                        ? "border-red-500"
                                        : "border-grey-300"
                                } bg-white py-2 pl-3 pr-10 text-text-primary shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm appearance-none`}
                                required={field.required}
                                defaultValue={field.default}
                            >
                                {field.options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-grey-500">
                                <svg className="h-5 w-5 fill-current" viewBox="0 0 20 20">
                                    <path
                                        fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                        {validationErrors[field.name] && (
                            <p className="text-red-500 text-xs mt-1">
                                {validationErrors[field.name]}
                            </p>
                        )}
                    </div>
                );

            case "canvas":
                return (
                    <div className="col-span-2">
                        <div className="border border-gray-300 p-2 rounded-lg flex justify-items-center flex-col items-center">
                            <canvas ref={canvasRef} />
                            <div className="mt-4 bg-gray-100 p-4 rounded-lg">
                                <div className="flex flex-wrap items-center justify-between gap-4">
                                    <div className="flex items-center space-x-2">
                                        <label
                                            htmlFor="brush-size"
                                            className="text-sm font-medium text-gray-700 flex items-center"
                                        >
                                            <FaBrush className="mr-2" /> Brush Size:
                                        </label>
                                        <input
                                            id="brush-size"
                                            type="range"
                                            min="1"
                                            max="50"
                                            value={brushSize}
                                            onChange={handleBrushSizeChange}
                                            className="w-32 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                        />
                                        <span className="text-sm font-medium text-gray-700">
                                            {brushSize}px
                                        </span>
                                    </div>
                                    <div className="flex space-x-2">
                                        <button
                                            type="button"
                                            onClick={handleUndo}
                                            className="p-2 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            title="Undo"
                                        >
                                            <FaUndo />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleRedo}
                                            className="p-2 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            title="Redo"
                                        >
                                            <FaRedo />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleReset}
                                            className="p-2 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            title="Reset"
                                        >
                                            <FaTrash />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleZoomIn}
                                            className="p-2 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            title="Zoom In"
                                        >
                                            <FaSearchPlus />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleZoomOut}
                                            className="p-2 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            title="Zoom Out"
                                        >
                                            <FaSearchMinus />
                                        </button>
                                        <button
                                            type="button"
                                            onClick={resetZoom}
                                            className="p-2 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            title="Reset Zoom"
                                        >
                                            <FaSearch />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            default:
                return (
                    <div className="col-span-2 md:col-span-1">
                        <input
                            type={field.type}
                            name={field.name}
                            className={`mt-1 block w-full rounded-md border ${
                                validationErrors[field.name] ? "border-red-500" : "border-grey-300"
                            } shadow-sm py-2 px-3 bg-white text-text-primary focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm`}
                            required={field.required}
                            defaultValue={field.default}
                            placeholder={field.placeholder}
                            min={field.min}
                            max={field.max}
                            step={field.step || (field.type === "number" ? "any" : undefined)}
                        />
                        {validationErrors[field.name] && (
                            <p className="text-red-500 text-xs mt-1">
                                {validationErrors[field.name]}
                            </p>
                        )}
                    </div>
                );
        }
    };
    return (
        <div className="bg-white">
            <div className="mb-8 flex items-center justify-start gap-4">
                <button
                    onClick={onBack}
                    className="inline-flex items-center px-4 py-2 border border-grey-300 text-sm font-medium rounded-md text-grey-700 bg-white hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                    <ArrowLeft className="h-5 w-5" />
                </button>
                <h1 className="text-2xl font-bold text-text-primary">{model.name}</h1>
            </div>
            <div className="h-[1px] w-full bg-grey-300 mb-8"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                    <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6">
                        {model.fields.map((field) => (
                            <div
                                key={field.name}
                                className={
                                    field.width === "full"
                                        ? "col-span-2"
                                        : "col-span-2 md:col-span-1"
                                }
                            >
                                <label className="block text-sm font-medium text-text-secondary mb-1">
                                    {field.label}
                                    {field.required && <span className="text-red-500"> *</span>}
                                </label>
                                {renderField(field)}
                            </div>
                        ))}
                        <div className="col-span-2">
                            <button
                                type="submit"
                                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors"
                                disabled={loading}
                            >
                                {loading ? "Processing..." : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>

                <div className="bg-gray-100 p-6 rounded-lg min-h-[500px] flex flex-col">
                    {!result && !loading && !error ? (
                        <div className="flex-grow flex items-center justify-center">
                            <div className="text-center">
                                <Wand2 className="h-16 w-16 text-primary mb-4 mx-auto" />
                                <p className="text-lg text-text-secondary">
                                    Submit the form to see the magic happen!
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full h-full flex flex-col">
                            {loading ? (
                                <div className="flex-grow flex items-center justify-center">
                                    <div className="text-center w-full max-w-xs">
                                        <p className="text-text-secondary mb-4">Processing...</p>
                                        <div className="relative pt-1 w-full">
                                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-300">
                                                <div
                                                    style={{ width: `${progress}%` }}
                                                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary transition-all duration-500 ease-out"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : error ? (
                                <div className="text-danger bg-danger-light p-4 rounded-md w-full">
                                    <p className="font-semibold">Error:</p>
                                    <p>
                                        {typeof error === "string"
                                            ? error
                                            : JSON.stringify(error, null, 2)}
                                    </p>
                                </div>
                            ) : (
                                <div className="flex-grow flex">
                                    {result && (
                                        <div className="w-full">
                                            <h3 className="text-lg font-semibold mb-4">Result</h3>
                                            <img
                                                src={result}
                                                alt="Inpainted result"
                                                className="w-full h-auto object-contain rounded-lg"
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InpaintingTool;
