import { combineReducers } from "redux";
import authReducer from "./reducers/auth.reducer";
import generationReducer from "./reducers/generation.reducer";
import orgReducer from "./reducers/organization.reducer";
import exploreImagesReducer from "./reducers/exploreImages.reducer";

const rootReducer = combineReducers({
    authenticationDetails: authReducer,
    generationDetails: generationReducer,
    organizationDetails: orgReducer,
    exploreImageDetails: exploreImagesReducer,
});

export default rootReducer;
