import axios from "axios";

const BASE_URL = "https://rayfashionhub.pixelbinz0.de";

export const submitJob = async (endpoint, formData) => {
    try {
        const response = await axios.post(`${BASE_URL}${endpoint}/submit-job`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error("Full error response:", error.response);
        throw error;
    }
};

// export const submitJob = async (endpoint, formData) => {
//   try {
//     const response = await axios.post(
//       `${BASE_URL}${endpoint}/submit-job`,
//       formData,
//       {
//         headers: { "Content-Type": "multipart/form-data" },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Error submitting job:", error);
//     throw error;
//   }
// };

export const getJobStatus = async (endpoint, jobId) => {
    try {
        const response = await axios.get(`${BASE_URL}${endpoint}/status/${jobId}`);
        return response.data;
    } catch (error) {
        console.error("Error getting job status:", error);
        throw error;
    }
};

export const pollJobStatus = async (
    endpoint,
    jobId,
    onSuccess,
    onError,
    maxAttempts = 120,
    interval = 2000,
) => {
    let attempts = 0;

    const poll = async () => {
        try {
            const result = await getJobStatus(endpoint, jobId);
            if (result.status === "done") {
                onSuccess(result);
            } else if (result.status === "error") {
                onError(result);
            } else if (attempts < maxAttempts) {
                attempts++;
                setTimeout(poll, interval);
            } else {
                onError({ error: "Max polling attempts reached" });
            }
        } catch (error) {
            onError(error);
        }
    };

    poll();
};

export const formatResponse = (response) => {
    if (Array.isArray(response)) {
        return response.join("\n");
    } else if (typeof response === "string") {
        return response;
    } else {
        return JSON.stringify(response, null, 2);
    }
};
