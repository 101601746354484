import { GenerationContext } from "@components/GenerationForm/GenerationContext";
import GenerationSettingsAndSubmission from "@components/GenerationForm/GenerationSettingsAndSubmission";
import CreditLimitExceededPopup from "@components/Popup/CreditLimitExceededPopup";
import { checkGenerationStatus, startGeneration } from "@reduxMain/actions/generationActions";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import PaymentsService from "@src/services/payments.service";
import { useLocation, useHistory } from "react-router-dom";
import { ASPECT_RATIOS } from "@common/constants/constants";

const GenerationForm = ({ isAtTop, setIsCreditLimitExceeded }) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        errors,
        generationPrompt,
        handleSetGenerationPrompt,
        generationStillPending,
        setGenerationStillPending,
        setImageCount,
        promptInputRef,
    } = useContext(GenerationContext);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [generationSettingsIsOpen, setGenerationSettingsIsOpen] = useState(false);
    const [isMobileSubmitButtonVisible, setIsMobileSubmitButtonVisible] = useState(false);
    const [_formSubmitClick, _setFormSubmitClick] = useState(false);
    const [activePlaceHolderIdx, setActivePlaceholderIdx] = useState(0);
    const [{ organization }, generationDetails] = useSelector((state) => [
        state.organizationDetails,
        state.generationDetails,
    ]);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const formPlaceholders = [
        "Enter your prompt here",
        "F1 car studio pics taken by hight definition camera, FP 20 dcm, convex lens",
        "Man walking his dog along the beach on a sunny day",
        "Spaceship in a galaxy under the night sky",
    ];

    const handleTextareaInput = (e) => {
        let normalizedText = e.target.value.replace(/[\r\n]+/g, "");
        setValue("generationPrompt", normalizedText);
        handleSetGenerationPrompt(normalizedText);
        adjustTextareaHeight(e.target);
    };

    const adjustTextareaHeight = (el) => {
        el.style.height = "auto";
        el.style.height = `${el.scrollHeight}px`;
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    const combinedRef = (e) => {
        promptInputRef.current = e;
        register("generationPrompt", { required: true }).ref(e);
    };

    const handleGenerateClickEvent = (requiredFieldErrors) => {
        // TODO: Add event on GA first
        // EventEmitter.dispatch(GenerationAttempt, {
        //     validation_error: requiredFieldErrors,
        //     login_type: "normal login",
        // });
    };

    const onSubmit = async (data) => {
        setIsFormDisabled(true);

        const formData = {
            "input.prompt": data.generationPrompt,
            "input.generation_style": data.style.value,
            "input.aspect_ratio": data.aspectRatio,
            "input.num_images_per_prompt": data.imageCount.value,
            "input.seed": parseInt(data.seed) || 65432,
            "input.num_inference_steps": 20,
            "input.guidance_scale": 7.5,
            "input.use_deepcache": false,
        };
        if (organization) {
            PaymentsService.getTotalUsage(organization._id)
                .then((orgUsageDetails) => {
                    const { used: creditsUsed, total: totalCredits } = orgUsageDetails.data.credits;

                    if (totalCredits - creditsUsed >= 5) {
                        startGeneration(organization._id, formData, dispatch).then((res) => {
                            setImageCount(parseInt(res.data.generation.outputImageCount));
                            setGenerationStillPending(false);
                            setIsFormDisabled(false);

                            history.push(
                                `/organization/${organization?._id}/dashboard/my-generations`,
                            );

                            checkGenerationStatus(
                                organization._id,
                                res.data.generation._id,
                                res.data.generation.generationId,
                                dispatch,
                            ).catch((res) => {
                                if (res.data.status === "RUNNING") {
                                    setGenerationStillPending(true);
                                } else {
                                    console.error(res);
                                }
                            });
                        });
                    } else {
                        setIsCreditLimitExceeded(true);
                        setIsFormDisabled(false);
                    }
                })
                .catch((err) => {
                    toast.error("There was an error starting the generation");
                    setIsFormDisabled(false);
                });
        }
    };

    useEffect(() => {
        setIsMobileSubmitButtonVisible(generationPrompt ? true : false);
        if (promptInputRef && generationPrompt) adjustTextareaHeight(promptInputRef.current);
    }, [generationPrompt]);

    useEffect(() => {
        toast.dismiss();
        if (_formSubmitClick && !_.isEmpty(errors)) {
            let requiredFieldErrors = [];
            Object.keys(errors).forEach((key) => {
                let ErrorType = errors[key].type;
                requiredFieldErrors = [...requiredFieldErrors, `${key} ${ErrorType} error`];

                if (key === "generationPrompt" && ErrorType === "required")
                    toast.error("Prompt is required");
            });
            handleGenerateClickEvent(requiredFieldErrors);
            _setFormSubmitClick(false);
        } else {
            if (promptInputRef.current) {
                promptInputRef.current.focus();
                promptInputRef.current.blur();
            }

            if (typeof window !== "undefined")
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
        }
    }, [_formSubmitClick, errors]);

    useEffect(() => {
        if (!generationStillPending && generationDetails?.pendingGenerationDetails?._id) {
            checkGenerationStatus(
                organization._id,
                generationDetails?.pendingGenerationDetails?._id,
                generationDetails?.pendingGenerationDetails?.generationId,
                dispatch,
            )
                .then(({ data }) => {
                    setIsFormDisabled(false);
                })
                .catch((res) => {
                    if (res.data.status === "RUNNING") {
                        setIsFormDisabled(false);
                        setGenerationStillPending(true);
                    } else {
                        console.error(res);
                    }
                });
        }
    }, [generationStillPending]);

    // useEffect(() => {
    //     const placeholderSwitchTimeout = setTimeout(() => {
    //         setAnimate(true);
    //         setTimeout(() => {
    //             setAnimate(false);
    //             setActivePlaceholderIdx((prevIndex) => (prevIndex + 1) % placeholders.length);
    //         }, 500);
    //     }, 2000);
    //     return {
    //         clearTimeout(placeholderSwitchTimeout);
    //     }
    // });
    return (
        <form
            id="generationForm"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className={`flex flex-col w-full p-4 md:px-20 bg-grey-black md:bg-transparent shadow-modal md:shadow-none border md:border-0 border-grey-700 rounded-t-3xl md:rounded-0 z-20 md:transition-form-cta md:duration-500 md:ease-in-out ${
                generationDetails.totalGenerations === 0 &&
                location?.pathname.includes("/dashboard/explore") &&
                isAtTop
                    ? "md:py-[60px]"
                    : "md:py-4"
            }`}
        >
            {/* {generationDetails.totalGenerations === 0 &&
                location?.pathname.includes("/dashboard/explore") && ( */}
            <h1
                className={`hidden md:block text-nowrap truncate text-5xl font-normal -tracking-0.5 font-clash md:transition-form-cta md:duration-500 md:ease-in-out ${
                    isAtTop &&
                    generationDetails.totalGenerations === 0 &&
                    location?.pathname.includes("/dashboard/explore")
                        ? "opacity-100 max-h-56 mb-8"
                        : "opacity-0 max-h-0 mb-0"
                }`}
            >
                Enter prompt to start generating
            </h1>
            {/* )} */}
            <div className="flex flex-col md:flex-row w-full items-center gap-3 md:items-start z-10 bg-grey-700 p-2 rounded-12 has-[:disabled]:bg-grey-600 has-[:disabled]:cursor-progress">
                <div className="w-full flex relative">
                    <textarea
                        ref={combinedRef}
                        className="peer opacity-0 placeholder-shown:opacity-100 bg-grey-700 focus:opacity-100 focus:break-words whitespace-pre text-nowrap text-ellipsis focus:text-wrap overflow-x-hidden focus:overflow-y-scroll my-3 pl-3 text-white rounded-4 outline-none w-full h-auto max-h-6 focus:max-h-40 resize-none disabled:text-grey-400 disabled:bg-grey-600 disabled:cursor-progress"
                        onInput={handleTextareaInput}
                        onKeyDown={handleKeyDown}
                        placeholder={formPlaceholders[activePlaceHolderIdx]}
                        rows={1}
                        disabled={isFormDisabled}
                    />
                    <div className="peer-focus:opacity-0 opacity-100 truncate pointer-events-none absolute top-0 left-0 w-full my-3 mr-3 pl-3">
                        {generationPrompt ?? ""}
                    </div>
                </div>
                <GenerationSettingsAndSubmission
                    generationSettingsIsOpen={generationSettingsIsOpen}
                    setGenerationSettingsIsOpen={setGenerationSettingsIsOpen}
                    className="hidden md:flex"
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    _setFormSubmitClick={_setFormSubmitClick}
                    isFormDisabled={isFormDisabled}
                    handleSubmit={handleSubmit(onSubmit)}
                />
            </div>
            <GenerationSettingsAndSubmission
                generationSettingsIsOpen={generationSettingsIsOpen}
                setGenerationSettingsIsOpen={setGenerationSettingsIsOpen}
                className={`md:hidden w-full ${
                    isMobileSubmitButtonVisible ? "flex h-full" : "hidden h-0 opacity-0"
                }`}
                register={register}
                setValue={setValue}
                watch={watch}
                _setFormSubmitClick={_setFormSubmitClick}
                isButtonDisabled={isFormDisabled}
                handleSubmit={handleSubmit(onSubmit)}
            />
        </form>
    );
};

export default GenerationForm;
