import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Icon from "@components/Icon";
import { useDispatch, useSelector } from "react-redux";
import PaymentsService from "@src/services/payments.service";
import { formatBytes } from "@common/utils/common-utils";
import environment from "@src/environment";
import Select from "react-tailwindcss-select";
import { setCurrentOrganization } from "@common/redux/actions/orgActions";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../Button";

const SettingsMenuModal = ({ user, isOpen, setIsOpen, onLogoutClick, usageData }) => {
    const [{ organization, organizations, member }] = useSelector((state) => [
        state.organizationDetails,
    ]);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const settingsMenuRef = useRef();
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleCloseClick = () => {
        setIsOpen(false);
    };

    const handleResize = () => {
        if (settingsMenuRef.current) {
            const rect = settingsMenuRef.current.getBoundingClientRect();
            const topPosition = rect.top;
            const availableHeight = window.innerHeight - topPosition;
            settingsMenuRef.current.style.maxHeight = availableHeight + "px";
        }
    };

    useEffect(() => {
        handleResize();
    }, [isOpen]);

    useLayoutEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleResize);
        };
    }, []);

    return (
        <>
            {/* Desktop Menu */}
            <div className={`${isOpen ? "hidden md:flex" : "hidden"}`}>
                <div
                    className={`fixed inset-0 items-center justify-center bg-black bg-opacity-30 z-30`}
                    onClick={handleCloseClick}
                ></div>
                <div
                    ref={settingsMenuRef}
                    className="absolute top-16 right-20 w-64 bg-white text-gray-800 rounded-lg shadow-lg border border-gray-200 z-50 overflow-auto"
                >
                    <div
                        // onClick={() => setIsSubMenuOpen(true)}
                        className="flex flex-row items-center px-4 rounded-t-lg w-full h-[68px] bg-gray-50 text-gray-800 outline-none hover:bg-gray-100 active:bg-gray-200 border-b border-gray-200"
                    >
                        <span className="flex flex-row items-center gap-2.5 max-w-64">
                            {user?.profilePicUrl ? (
                                <img
                                    className="h-10 w-10 rounded-full"
                                    src={user?.profilePicUrl}
                                    draggable={false}
                                    alt={`${user?.firstName} ${user?.lastName}`}
                                />
                            ) : (
                                <div className="bg-blue-500 text-white font-semibold rounded-full h-10 w-10 text-lg flex items-center justify-center">
                                    {user?.firstName[0].toUpperCase()}
                                </div>
                            )}
                            <span className="flex flex-col w-full overflow-hidden text-left">
                                <span className="font-semibold text-base text-ellipsis overflow-hidden text-nowrap">
                                    {user?.firstName} {user?.lastName}
                                </span>
                                <span className="text-gray-500 font-normal text-sm text-ellipsis overflow-hidden text-nowrap">
                                    {user?.email}
                                </span>
                            </span>
                            {/* <Icon
                type="chevronRight"
                className={{
                  wrapper: "w-5 h-5 text-gray-400",
                }}
              /> */}
                        </span>
                    </div>

                    {/* Sub menu */}
                    {/* <div
                        className={`absolute top-0 right-0 w-full h-full bg-white overflow-auto text-gray-800 transform transition-sub-menu duration-300 ease-in-out ${
                            isSubMenuOpen ? "translate-x-0" : "translate-x-full"
                        }`}
                    >
                        <div className="flex items-center justify-between border-b border-gray-200">
                            <button
                                tabIndex={isSubMenuOpen ? 0 : -1}
                                onClick={() => setIsSubMenuOpen(false)}
                                className="flex text-base justify-start items-center gap-2 px-4 rounded-t-lg outline-none border border-transparent focus:border-gray-300 text-gray-800 w-full h-13 hover:bg-gray-100"
                            >
                                <Icon type="chevronLeft" className={{ wrapper: "text-gray-600" }} />
                                <span className="font-semibold">Select Organization</span>
                            </button>
                        </div>
                        <div className="flex flex-col">
                            {organizations.map((org, idx) => (
                                <button
                                    tabIndex={isSubMenuOpen ? 0 : -1}
                                    key={org._id}
                                    onClick={() => {
                                        if (organization?._id !== org._id) {
                                            setCurrentOrganization(org, member, dispatch).then(
                                                () => {
                                                    history.push(
                                                        location.pathname.replace(
                                                            /\/organization\/[\d]+\//,
                                                            `/organization/${org._id}/`,
                                                        ),
                                                    );
                                                    history.go(0);
                                                },
                                            );
                                        }
                                        setIsSubMenuOpen(false);
                                    }}
                                    className={`flex items-center gap-2.5 text-base hover:bg-gray-100 transition h-13 duration-200 px-4 py-2 cursor-pointer text-left outline-none border border-transparent focus:border-gray-300 ${
                                        organization?._id === org?._id ? "bg-gray-200" : ""
                                    }`}
                                >
                                    <Icon type="globe" className={{ wrapper: "text-gray-600" }} />
                                    {org.name}
                                </button>
                            ))}
                        </div>
                    </div> */}

                    {/* <div className="flex flex-col py-3 px-4 bg-gray-50 border-b border-gray-200">
            <div className="flex flex-col gap-3">
              <div className="flex flex-col">
                <div className="flex items-center justify-between font-semibold text-base">
                  <span>Generations left</span>
                  <span>
                    {usageData?.generationsLeft ?? 0}/
                    {usageData?.totalGenerations ?? 0}
                  </span>
                </div>
                <div className="w-full bg-gray-200 h-2 rounded-full my-2">
                  <div
                    className="bg-blue-500 h-full rounded-full"
                    style={{
                      width: `${
                        ((usageData?.generationsLeft ?? 0) /
                          (usageData?.totalGenerations ?? 1)) *
                        100
                      }%`,
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full pb-1">
                <Button
                  as={"a"}
                  href={`https://www.${environment.BHARAT_DIFFUSION_PLATFORM_DOMAIN}/pricing`}
                  className="bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                >
                  Upgrade Plan
                </Button>
              </div>
            </div>
          </div> */}

                    <div className="flex flex-col">
                        <button
                            onClick={() => {
                                handleCloseClick();
                                onLogoutClick();
                            }}
                            className="flex gap-2.5 items-center py-3 px-4 text-gray-800 hover:bg-gray-100"
                        >
                            <Icon type="logout" className={{ wrapper: "text-gray-600" }} />
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            <div>
                <div
                    className={`fixed inset-0 bg-black bg-opacity-30 z-30 ${
                        isOpen ? "block md:hidden" : "hidden"
                    }`}
                    onClick={handleCloseClick}
                ></div>

                <div
                    className={`fixed top-0 right-0 bottom-0 w-72 bg-white text-gray-800 transform transition-transform duration-300 ease-in-out overflow-y-auto overscroll-none ${
                        isOpen ? "translate-x-0" : "translate-x-full"
                    } md:hidden z-50`}
                >
                    <div className="flex flex-col bg-gray-50 gap-8 items-center pt-6">
                        <div className="flex flex-col gap-2 items-center px-4">
                            {user?.profilePicUrl ? (
                                <img
                                    className="h-32 w-32 rounded-full"
                                    src={user?.profilePicUrl}
                                    draggable={false}
                                    alt={`${user?.firstName} ${user?.lastName}`}
                                />
                            ) : (
                                <div className="bg-blue-500 text-white font-bold rounded-full h-32 w-32 text-6xl flex flex-none items-center justify-center">
                                    {user?.firstName[0].toUpperCase()}
                                </div>
                            )}
                            <p className="font-semibold text-gray-800">
                                {user?.firstName} {user?.lastName}
                            </p>
                            <p className="text-gray-500 text-sm">{user?.email}</p>
                        </div>
                        {/* <div className="flex flex-col py-3 px-4 w-full">
              <div className="flex items-center justify-between text-base text-gray-700">
                <span>Generations left</span>
                <span>
                  {usageData?.generationsLeft ?? 0}/
                  {usageData?.totalGenerations ?? 0}
                </span>
              </div>
              <div className="w-full bg-gray-200 h-2 rounded-full my-2">
                <div
                  className="bg-blue-500 h-full rounded-full"
                  style={{
                    width: `${
                      ((usageData?.generationsLeft ?? 0) /
                        (usageData?.totalGenerations ?? 1)) *
                      100
                    }%`,
                  }}
                ></div>
              </div>
            </div> */}
                    </div>
                    <div className="flex flex-col">
                        {/* <Link
                            to={
                                organization
                                    ? `/organization/${organization._id}/dashboard`
                                    : "/dashboard"
                            }
                            className="flex gap-2.5 items-center py-3 px-4 text-gray-700 hover:bg-gray-100"
                            onClick={handleCloseClick}
                        >
                            <Icon type="home" className={{ icon: "text-gray-600" }} />
                            <span>Home</span>
                        </Link>
                        <Link
                            to={
                                organization
                                    ? `/organization/${organization._id}/generations/history`
                                    : "/generations/history"
                            }
                            className="flex gap-2.5 items-center py-3 px-4 text-gray-700 hover:bg-gray-100"
                            onClick={handleCloseClick}
                        >
                            <Icon type="history" className={{ icon: "text-gray-600" }} />
                            <span>History</span>
                        </Link>
                        <a
                            href={`${environment.MITSUKI_MAIN_DOMAIN}/terms-of-use`}
                            className="flex gap-2.5 items-center py-3 px-4 text-gray-700 hover:bg-gray-100"
                            onClick={handleCloseClick}
                        >
                            <Icon type="openInNew" className={{ icon: "text-gray-600" }} />
                            <span>Terms & services</span>
                        </a> */}
                        <button
                            onClick={() => {
                                handleCloseClick();
                                onLogoutClick();
                            }}
                            className="flex gap-2.5 items-center py-3 px-4 text-red-600 hover:bg-gray-100"
                        >
                            <Icon type="logout" className={{ icon: "text-red-600" }} />
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingsMenuModal;
