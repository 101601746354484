import SettingsMenuModal from "@common/components/GlobalNavBar/SettingsMenuModal";
import { logout } from "@common/redux/actions/authActions";
import Icon from "@components/Icon";
import BDLogo from "@public/BDLogoWhite.jpg";
import environment from "@src/environment";
import PaymentsService from "@src/services/payments.service";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import GenerationForm from "../GenerationForm/index";
import IconButton from "../IconButton";
import CreditLimitExceededPopup from "../Popup/CreditLimitExceededPopup";
import LogoutPopup from "../Popup/LogoutPopup";

const Navbar = ({ user }) => {
    const dispatch = useDispatch();

    const [settingsIsOpen, setSettingsIsOpen] = useState(false);
    const [logoutPopupIsOpen, setLogoutPopupIsOpen] = useState(false);
    const [isAtTop, setIsAtTop] = useState(true);
    const [usageData, setUsageData] = useState({});
    const [isCreditLimitExceeded, setIsCreditLimitExceeded] = useState(false);
    const [formRect, setFormRect] = useState({});

    const formWrapperRef = useRef();
    const location = useLocation();

    const toggleSettingsMenu = () => {
        setSettingsIsOpen(!settingsIsOpen);
    };

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }

        setFormRect(formWrapperRef.current?.getBoundingClientRect() ?? {});
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleLogout = () => {
        logout(dispatch).catch((err) => console.error(err));
    };

    return (
        <>
            {user && (
                <nav
                    className={`flex w-full justify-center items-center top-0 sticky z-50 ${
                        user ? "" : ""
                    }`}
                    style={{ backgroundColor: isAtTop ? "#fff" : "#f8f9fa" }} // Light background color for light mode
                >
                    <div
                        className={`flex flex-col w-full min-h-[66px] ${
                            user ? "md:min-h-[66px]" : ""
                        } transition-colors duration-500 ease-in-out border-b border-gray-300`}
                    >
                        <div
                            className={`px-4 flex justify-center items-center w-full min-h-[66px] ${
                                user ? "md:px-20" : "md:px-20"
                            }`}
                        >
                            <div
                                className={`flex flex-row justify-between items-center ${
                                    user ? "w-full" : "w-full"
                                }`}
                            >
                                <Link
                                    to="/models"
                                    className="text-gray-900 font-bold text-lg"
                                    aria-label="FashionHub PixelBin" // Accessibility improvement
                                >
                                    <img
                                        src="https://cdn.pixelbin.io/v2/dummy-cloudname/original/__logos__/pixelbin/pixelbin_logo_dark.png"
                                        alt="FashionHub PixelBin"
                                        className="h-8" // Adjust the height as per your design
                                    />
                                </Link>
                                {user && (
                                    <div className="flex flex-row gap-1 items-center">
                                        <button
                                            className="rounded-full flex items-center h-10 px-2 transition hover:bg-gray-100 border border-gray-200"
                                            onClick={toggleSettingsMenu}
                                            aria-label="Settings menu"
                                        >
                                            {user.profilePicUrl ? (
                                                <img
                                                    className="h-8 m-1 rounded-full"
                                                    src={user.profilePicUrl}
                                                    alt="User profile"
                                                    draggable={false}
                                                />
                                            ) : (
                                                <>
                                                    <div className="hidden bg-blue-500 text-white m-1 font-semibold rounded-full h-8 w-8 text-lg md:flex items-center justify-center">
                                                        {user?.firstName[0].toUpperCase()}
                                                    </div>
                                                    <Icon
                                                        type="userProfile"
                                                        className={{
                                                            wrapper:
                                                                "block md:hidden w-10 h-10 fill-gray-600",
                                                        }}
                                                    />
                                                </>
                                            )}
                                            <div className="hidden md:flex items-center ml-2">
                                                <Icon
                                                    type="chevronDown"
                                                    className={{
                                                        wrapper: "fill-gray-600 rounded-full",
                                                    }}
                                                />
                                            </div>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <SettingsMenuModal
                        user={user}
                        isOpen={settingsIsOpen}
                        setIsOpen={setSettingsIsOpen}
                        onLogoutClick={() => handleLogout()}
                        usageData={usageData}
                    />
                    <LogoutPopup
                        isOpen={logoutPopupIsOpen}
                        setIsOpen={setLogoutPopupIsOpen}
                        onCancel={() => setLogoutPopupIsOpen(false)}
                    />
                    <CreditLimitExceededPopup
                        isOpen={isCreditLimitExceeded}
                        setIsOpen={setIsCreditLimitExceeded}
                    />
                </nav>
            )}

            {/* Back to top button */}
            {/* <div
        className="fixed w-screen h-0 flex justify-center items-center z-40"
        style={{ top: `${Math.max(formRect?.bottom, 0) + 154}px` }}
      >
        <IconButton
          type="arrow"
          tooltipLabel="Back to top"
          tabIndex={"-1"}
          className={{
            button: `outline-none enabled:hover:bg-gray-200 bg-white fill-gray-800 w-13 h-13 rounded-full transition-scroll-top-button duration-150 ease-in-out ${
              isAtTop ? "max-w-0 max-h-0" : "max-w-14 max-h-14"
            }`,
            wrapper: "h-13 mb-1",
            icon: "rotate-90 stroke-gray-800",
          }}
          onClick={() => {
            if (typeof window !== "undefined")
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
          }}
        />
      </div> */}
        </>
    );
};

export default Navbar;
