import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Select from "react-tailwindcss-select";
import realisticTb from "@public/generationStyles/realistic.jpg";
import cinematicTb from "@public/generationStyles/cinematic.jpg";
import cyberpunkTb from "@public/generationStyles/cyberpunk.jpg";
import digitalartTb from "@public/generationStyles/digitalart.jpg";
import fantasyTb from "@public/generationStyles/fantasy.jpg";
import animeTb from "@public/generationStyles/anime.jpg";
import IconButton from "../IconButton";
import { ASPECT_RATIOS, GENERATION_STYLES, IMAGE_COUNT_OPTIONS } from "@common/constants/constants";

const GenerationSettingsModal = ({ isOpen, setIsOpen, register, setValue, watch }) => {
    const [selectedStyleIdx, setSelectedStyleIdx] = useState(0);
    const [aspectRatioValue, setAspectRatioValue] = useState("square");
    const [outputImages, setOutputImages] = useState({ value: 4, label: "4" });
    const [seedValue, setSeedValue] = useState("");
    const modalBaseRef = useRef();
    const styleRefs = useRef([]);

    const generationStyles = [
        { value: "realistic", url: realisticTb },
        { value: "cinematic", url: cinematicTb },
        { value: "cyberpunk", url: cyberpunkTb },
        { value: "digitalart", url: digitalartTb },
        { value: "fantasy", url: fantasyTb },
        { value: "anime", url: animeTb },
    ].map((style) => {
        return { ...GENERATION_STYLES.find((val) => val.value === style.value), ...style };
    });

    const style = watch("style");
    const aspectRatio = watch("aspectRatio");
    const imageCount = watch("imageCount");
    const seed = watch("seed");

    const handleResize = () => {
        if (modalBaseRef.current) {
            const rect = modalBaseRef.current.getBoundingClientRect();
            const topPosition = rect.top;
            const availableHeight = window.innerHeight - topPosition;
            modalBaseRef.current.style.maxHeight = availableHeight + "px";
        }
    };

    useEffect(() => {
        handleResize();
    }, [isOpen]);
    useLayoutEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleResize);
        };
    }, []);

    useEffect(() => {
        setValue("style", generationStyles[selectedStyleIdx]);
        setValue("aspectRatio", aspectRatioValue);
        setValue("imageCount", outputImages);
        setValue("seed", seedValue);
    }, []);

    useEffect(() => {
        if (style) {
            setSelectedStyleIdx(generationStyles.findIndex((val) => val.value === style.value));
        }
        if (aspectRatio) {
            setAspectRatioValue(aspectRatio);
        }
        if (imageCount) {
            setOutputImages(imageCount);
        }
        if (seed) {
            setSeedValue(seed);
        }
    }, [style, aspectRatio, imageCount, seed]);

    const handleOutsideClick = (e) => {
        setIsOpen(false);
    };

    const preventFormSubmission = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    return (
        <div className={`${isOpen ? "block" : "hidden"}`}>
            <div
                ref={modalBaseRef}
                className="flex flex-col gap-8 md:absolute md:top-15 md:-right-3 md:bg-grey-800 text-white md:p-8 md:rounded-lg md:w-[500px] md:shadow-modal text-lg font-normal md:z-50 overflow-y-scroll"
            >
                <div className="flex flex-col gap-3">
                    <div className="w-full flex justify-between">
                        <h2 className="">Generation styles</h2>
                        <div className="flex gap-2.5 fill-grey-300">
                            <IconButton
                                type="arrowLeft"
                                className={{ button: "hover:rounded-md" }}
                                onClick={() => {
                                    if (selectedStyleIdx > 0) {
                                        setSelectedStyleIdx(selectedStyleIdx - 1);

                                        styleRefs.current[selectedStyleIdx - 1].scrollIntoView({
                                            behavior: "smooth",
                                            block: "nearest",
                                        });
                                    } else {
                                        setSelectedStyleIdx(generationStyles.length - 1);
                                        styleRefs.current[
                                            generationStyles.length - 1
                                        ].scrollIntoView({
                                            behavior: "smooth",
                                            block: "nearest",
                                        });
                                    }
                                }}
                            />
                            <IconButton
                                type="arrowRight"
                                className={{ button: "hover:rounded-md" }}
                                onClick={() => {
                                    if (selectedStyleIdx < generationStyles.length - 1) {
                                        setSelectedStyleIdx(selectedStyleIdx + 1);
                                        styleRefs.current[selectedStyleIdx + 1].scrollIntoView({
                                            behavior: "smooth",
                                            block: "nearest",
                                        });
                                    } else {
                                        setSelectedStyleIdx(0);
                                        styleRefs.current[0].scrollIntoView({
                                            behavior: "smooth",
                                            block: "nearest",
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-3 overflow-x-scroll scroll-m-0 no-scrollbar">
                        {generationStyles.map((style, index) => (
                            <div
                                key={index}
                                ref={(el) => (styleRefs.current[index] = el)}
                                className={`flex-none w-[6.25rem] h-[7.625rem] rounded-lg cursor-pointer select-none relative ${
                                    selectedStyleIdx === index
                                        ? "border-2 border-white"
                                        : "border-2 border-transparent"
                                }`}
                                onClick={() => {
                                    styleRefs.current[index].scrollIntoView({
                                        behavior: "smooth",
                                        block: "nearest",
                                    });
                                    setSelectedStyleIdx(index);
                                    setValue("style", generationStyles[index]);
                                }}
                            >
                                <img
                                    src={style.url}
                                    alt={style}
                                    className="object-cover w-full h-full rounded-md pointer-events-none "
                                    draggable={false}
                                />
                                <p className="absolute bottom-1 inset-x-0 font-semibold text-center">
                                    {style.label}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <h2 className="">Aspect ratio</h2>
                    <div className="flex gap-2">
                        {Object.keys(ASPECT_RATIOS).map((ratio, index) => (
                            <button
                                type="button"
                                key={index}
                                className={`flex justify-center items-center gap-2 group outline-none hover:bg-grey-700 focus:bg-grey-700 px-4 py-2 rounded-lg border text-base ${
                                    aspectRatioValue === ratio
                                        ? "bg-grey-600 !text-grey-white focus:bg-grey-500 border-transparent font-semibold"
                                        : "bg-grey-800 !text-grey-200 border-grey-600 font-normal"
                                }`}
                                onClick={() => {
                                    setAspectRatioValue(ratio);
                                    setValue("aspectRatio", ratio);
                                }}
                            >
                                <div
                                    className={`h-4.5 bg-none border border-grey-200 rounded-sm ${
                                        ASPECT_RATIOS[ratio].style
                                    } ${
                                        aspectRatioValue === ratio ? "group-focus:border-white" : ""
                                    }`}
                                ></div>
                                {ASPECT_RATIOS[ratio].label}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="flex flex-row gap-6">
                    <div className="flex flex-col gap-3 w-full">
                        <label className="">No. of output images</label>
                        <Select
                            value={outputImages}
                            onChange={(value) => {
                                setOutputImages(value);
                                setValue("imageCount", value);
                            }}
                            options={IMAGE_COUNT_OPTIONS}
                            classNames={{
                                menuButton: ({ isDisabled }) =>
                                    "flex flex-row w-full h-auto rounded-lg *:*:*:bg-transparent bg-grey-800 text-white border border-gray-600 outline-none hover:bg-grey-700 active:bg-grey-800 active:border-grey-500",
                                menu: "absolute bottom-full z-50 w-full bg-grey-800 shadow-lg border border-gray-600 rounded py-2 mb-1.5 text-sm text-gray-700 overflow-scroll",
                                list: "flex flex-col gap-0.5",
                                // ChevronIcon: ({ open }) => "bg-red",
                                closeIcon: "bg-red",
                                listItem: ({ isSelected }) =>
                                    `block transition duration-200 px-2 py-1 cursor-pointer select-none truncate rounded ${
                                        isSelected
                                            ? `text-white bg-grey-600`
                                            : `text-gray-500 hover:bg-grey-700 hover:text-white`
                                    }`,
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-3 w-full">
                        <label className="">Seed value</label>
                        <input
                            value={seedValue}
                            {...register("seed")}
                            onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, "");
                                setSeedValue(value);
                                setValue("seed", value);
                            }}
                            onKeyDown={preventFormSubmission}
                            placeholder="Auto"
                            className="w-full p-2 rounded-lg bg-grey-800 text-white border border-gray-600 hover:bg-grey-700 active:bg-grey-800 active:border-grey-500 outline-none"
                        />
                    </div>
                </div>
            </div>
            <div
                id="popup-overlay"
                className="fixed inset-0 hidden md:flex items-start pt-36 justify-center bg-opacity-50 z-40"
                onClick={handleOutsideClick}
            />
        </div>
    );
};

export default GenerationSettingsModal;
